import { bugsMessage, PageLayout } from '@newfront-insurance/admin-ui/layout';
import { Text, Padding } from '@newfront-insurance/core-ui';

import { HeadTitle } from '@/client/components/head-title';

export default function Page404(): JSX.Element {
  return (
    <>
      <HeadTitle title="Page not found" />
      <PageLayout pageTitle="404 - Page not found">
        <Padding x={24} y={32}>
          <Text>{bugsMessage('Looks like the requested page does not exist')}</Text>
        </Padding>
      </PageLayout>
    </>
  );
}
